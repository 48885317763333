
  import { Component, Prop } from 'vue-property-decorator'
  import DefaultLayout from '@/layouts/Index.vue'

@Component({
  components: {
    AppBar: () =>
      import(
        /* webpackChunkName: "default-app-bar" */
        './AppBar.vue'
        ),
    ViewFooter: () => null,
  },
})
  export default class Index extends DefaultLayout {
  @Prop({ type: String, required: true }) model!: string
  @Prop({ type: String, required: true }) slug!: string

  @Prop({ type: Number }) uid?: number
  @Prop({ type: String }) returnTo?: string
  @Prop({ type: Array }) nested?: Array<string>

  get bar () {
    const { model, slug, uid, returnTo, nested } = this
    return { model, slug, uid, returnTo, nested }
  }
  }
